import { Component } from 'react';
import { FileInput } from 'react-materialize-forms';

import { can_sync_audio } from '../audioProcessing';

import Switch from './Switch';
import Select from './Select';

class Settings extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectData: [
				{ name: 'clap', value: 'Clap' , group: 'default'},
				{ name: 'beep', value: 'Beep', group: 'default'},
				{ name: 'upload', value: 'Upload', group: 'custom'}
			],
			uploadChosen: false,
			canSyncAudio: true,
		}
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
	    can_sync_audio(this.props.selectedFolderNumRecordings)
            .then((response) => {
				this.setState({
					canSyncAudio: response
				})
            })
            .catch((error) => {
                console.log(error);
            });
	}


	componentWillReceiveProps(parentState) {
	    can_sync_audio(parentState.selectedFolderNumRecordings)
            .then((response) => {
				this.setState({
					canSyncAudio: response
				})
            })
            .catch((error) => {
                console.log(error);
            });
	}

	handleSwitch(input) {
		this.props.handleSyncSwitch(input)
	}

	handleSelect(input) {
		if (input == "upload") {
			this.setState({
				uploadChosen: true
			});
		} else {
			this.setState({
				uploadChosen: false
			});
		}
	}

	handleUpload(input) {
		console.log(input);
	}

	render() {
		return (
			<div className="col m6 center-align">
				<h5> Sync Settings </h5>
				<Select
					label="Select Sync Sound"
					selectData={this.state.selectData}
					parentCallBack={this.handleSelect}
				/>
				{
					this.state.uploadChosen
					? <FileInput
						placeholder="Upload your sync sound"
						onChange={(input) => this.handleUpload(input)}
						/>
					: <div></div>
				}

				<Switch
					leftLabel="Synchronize"
					rightLabel="Server is Down"
					canSyncAudio={this.state.canSyncAudio}
					parentCallBack={(input) => this.handleSwitch(input)}
				/>
			</div>
		);
	}
}

export default Settings;
