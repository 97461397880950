import React from 'react';
import ReactHowler from './ReactHowler';
import raf from 'raf'; // requestAnimationFrame polyfill

import InputRange from './InputRange';
class FullControl extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleOnLoad = this.handleOnLoad.bind(this)
    this.handleOnEnd = this.handleOnEnd.bind(this)
    this.handleOnPlay = this.handleOnPlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.renderSeekPos = this.renderSeekPos.bind(this)
    this.handleLoopToggle = this.handleLoopToggle.bind(this)
    this.handleMuteToggle = this.handleMuteToggle.bind(this)
    this.incrementSeekPos = this.incrementSeekPos.bind(this)
    this.decrementSeekPos = this.decrementSeekPos.bind(this)
    this.handleMoveTime = this.handleMoveTime.bind(this)
  }

  componentWillReceiveProps(parentState) {
    this.setState({
      mute: parentState.mute,
      playing: parentState.playing
    });
  }

  componentWillUnmount () {
    this.clearRAF()
  }

  handleToggle () {
    this.setState({
      playing: !this.state.playing
    })
  }

  handleOnLoad () {
    this.setState({
      loaded: true,
      duration: this.player.duration()
    })
  }

  handleOnPlay () {
    this.setState({
      playing: true
    })
    this.renderSeekPos()
  }

  handleOnEnd () {
    this.setState({
      playing: false
    })
    this.clearRAF()
  }

  handleStop () {
    this.player.stop()
    this.setState({
      playing: false // Need to update our local state so we don't immediately invoke autoplay
    })
    this.renderSeekPos()
  }

  handleLoopToggle () {
    this.setState({
      loop: !this.state.loop
    })
  }

  handleMuteToggle () {
    this.setState({
      mute: !this.state.mute
    })
  }

  renderSeekPos () {
    this.setState({
      seek: this.player.seek()
    })
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos)
    }
  }

  handleMoveTime(progress) {
    var seek = progress / 100 * this.state.duration;
    this.setState({
      seek: this.player.seek(seek)
    });
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  incrementSeekPos () {
    var val = this.player.seek() + 1;
    if (val > this.state.duration) {
      val = this.state.duration;
    }
    this.setState({
      seek: this.player.seek(val)
    })
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos)
    }
  }

  decrementSeekPos () {
    var val = this.player.seek() - 1;
    if (val < 0) {
      val = 0;
    }
    this.setState({
      seek: this.player.seek(val)
    })
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos)
    }
  }

  clearRAF () {
    raf.cancel(this._raf)
  }

  render () {
    var progress;
    if (this.state.seek !== undefined) {
      progress = (this.state.seek.toFixed(2) / this.state.duration.toFixed(2) * 100);
    } else {
      progress = 0;
    }

    return (
      <div className='full-control' style={{paddingLeft: "10px"}}>
        <ReactHowler
          src={[this.props.sound]}
          format={[this.props.name.split(".").pop()]}
          playing={this.state.playing}
          onLoad={this.handleOnLoad}
          onPlay={this.handleOnPlay}
          onEnd={this.handleOnEnd}
          loop={this.state.loop}
          mute={this.state.mute}
          volume={this.state.volume}
          ref={(ref) => (this.player = ref)}
        />

        <p>{(this.state.loaded) ? this.props.name : 'Loading'}</p>

        <div className="progressBar row center-align">
          <span className='col s2'>{(this.state.seek !== undefined) ? this.state.seek.toFixed(2) : '0.00'}</span>
          <InputRange
            progress={progress}
            parentCallBack={this.handleMoveTime}
          />
          <span className='col s2'>{(this.state.duration) ? this.state.duration.toFixed(2) : 'NaN'}</span>
        </div>

        <div className="control row center-align">
          {/*<i className="loop col s1 material-icons" onClick={this.handleLoopToggle} style={{cursor: "pointer"}}>repeat</i> */}

          <i className="rewind col s1 offset-s2 material-icons" onClick={this.decrementSeekPos} style={{cursor: "pointer"}}>fast_rewind</i>

          {(this.state.playing)
            ? <i className="pause col s1 material-icons" onClick={this.handleToggle} style={{cursor: "pointer"}}>pause_circle_filled</i>
            : <i className="play col s1 material-icons" onClick={this.handleToggle} style={{cursor: "pointer"}}>play_circle_filled</i>
          }

          <i className="forward col s1 material-icons" onClick={this.incrementSeekPos} style={{cursor: "pointer"}}>fast_forward</i>

          <i className="stop col s1 material-icons" onClick={this.handleStop} style={{cursor: "pointer"}}>stop</i>

          <div className='volume col s5 offset-s1 row valign-wrapper'>
            {(this.state.mute)
              ? <i className="col s1 material-icons" onClick={this.handleMuteToggle} style={{cursor: "pointer", width: "44px"}}>volume_up</i>
              : <i className="col s1 material-icons" onClick={this.handleMuteToggle} style={{cursor: "pointer", width: "44px"}}>volume_off</i>
            }

            <label className='col s9'>
              <span className='slider-container'>
                <input
                  type='range'
                  min='0'
                  max='1'
                  step='.05'
                  value={this.state.volume}
                  onChange={e => this.setState({ volume: parseFloat(e.target.value) })}
                  style={{ verticalAlign: 'bottom' }}
                />
              </span>
            </label>
            <span className='col s2'>{this.state.volume.toFixed(2)}</span>
          </div>

        </div>
      </div>
    )
  }
}

export default FullControl;