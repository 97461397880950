import React from 'react';
import FullControl from './FullControl';

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recordings: {},
      allPlaying: false,
      allMuted: false,
      forcedChange: true
    }

    this.handleToggle = this.handleToggle.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
  }

  componentDidMount() {
    var recordings = {}
    this.props.recordings.forEach(item => {
      recordings[item.name] = URL.createObjectURL(item.recording);
    });
    this.setState({
      recordings
    })
  }

  componentWillReceiveProps(parentState) {
    var recordings = {}
    parentState.recordings.forEach(item => {
      recordings[item.name] = URL.createObjectURL(item.recording);
    });
    this.setState({
      recordings
    })
  }

  handleToggle () {
    this.setState({
      allPlaying: !this.state.allPlaying
    })
  }

  handleStop() {
    this.setState({
      allPlaying: false
    });

    window.Howler.stop();
  }

  handleMuteToggle() {
    this.setState({
      allMuted: !this.state.allMuted
    })
  }

  handleVolume(e) {
    window.Howler.volume(parseFloat(e.target.value));
  }

  render () {
    return (
      <div>
        <div className="row" style={{paddingTop: "1vh"}}>
          <div className="col s4">
            <a
              id="play-all"
              onClick={this.handleToggle}
              className="waves-effect waves-light btn teal lighten-2 centered"
              style={{width: "97.5%", marginLeft: "1.25%", marginRight: "1.25%"}}
            >
              <span>{this.state.allPlaying ? "PAUSE ALL" : "PLAY ALL"}</span><i className="material-icons">{this.state.allPlaying ? "pause_circle" : "play_circle"}</i>
            </a>
          </div>

          <div className="col s4">
            <a
              id="stop-all"
              onClick={this.handleStop}
              className="waves-effect waves-light btn teal lighten-2 centered"
              style={{width: "97.5%", marginLeft: "1.25%", marginRight: "1.25%"}}
            >
              <span>STOP ALL</span><i className="material-icons">stop</i>
            </a>
          </div>

          <div className='volume col s4 row valign-wrapper'>
            {(this.state.allMuted)
              ? <i className="col s2 material-icons" onClick={this.handleMuteToggle} style={{cursor: "pointer"}}>volume_up</i>
              : <i className="col s2 material-icons" onClick={this.handleMuteToggle} style={{cursor: "pointer"}}>volume_off</i>
            }

            <label className='col s10'>
              <span className='slider-container'>
                <input
                  type='range'
                  min='0'
                  max='1'
                  step='.05'
                  onChange={(e) => this.handleVolume(e)}
                  style={{ verticalAlign: 'bottom' }}
                />
              </span>
            </label>
          </div>
        </div>

        {Object.keys(this.state.recordings).map(key => {
            return <FullControl
                      key={key}
                      name={key}
                      sound={this.state.recordings[key]}
                      mute={this.state.allMuted}
                      playing={this.state.allPlaying}
                  />
        })}
      </div>
    )
  }
}

export default AudioPlayer;