import { Component } from 'react';

import { createFolder } from '../dropboxUtils';

class CreateFolder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newFolderName: "",
			isNameDuplicated: false
		}
		this.handleCreateFolderInput = this.handleCreateFolderInput.bind(this);
		this.createFolder = this.createFolder.bind(this);
	}

	handleCreateFolderInput(event) {
		this.setState({
			newFolderName: event.target.value,
			isNameDuplicated: false
		});
	}

	createFolder() {
		let path = "/" + this.state.newFolderName;
		createFolder(path, this.props.getCookie())
			.then((result) => {
				if ("error_summary" in result) {
					this.setState({
						isNameDuplicated: true
					});
				} else {
					this.props.addFolder({
						name: this.state.newFolderName,
						path_lower: path
					});
					this.setState({
						newFolderName: "",
						isNameDuplicated: false
					});
				}
			});
	}

	render() {
		return (
			<div id="add-recording-wrapper">
				<div className="row" style={{margin: "0px"}}>
					<div className="col m3">
						<a onClick={this.createFolder} id="create-new-recording" className="waves-effect waves-light btn teal lighten-2" style={{width: "100%", height: "40px", marginTop: "17px"}}>
							<i className="material-icons">create_new_folder</i>
						</a>
					</div>
					<div className="input-field col m9" style={{margin: "0px", marginTop: "15px", padding: "0px"}}>
						<input
							id="new-recording-name"
							type="text"
							value={this.state.newFolderName}
							onChange={this.handleCreateFolderInput}
						/>
						<label htmlFor="new-recording-name">New Folder Name</label>
						{this.state.isNameDuplicated
							? <span className="helper-text red-text left-align">Folder already exists</span>
							: <span className="helper-text"></span>
						}
					</div>
				</div>
			</div>

		);
	}
}

export default CreateFolder;