import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';


const CHORAL_API_URL = process.env.NODE_ENV === "production" ? "https://choral-api.tunertechnologies.com" : ""

const PING_URL = `${CHORAL_API_URL}/ping`;
const SYNC_URL = `${CHORAL_API_URL}/sync`;
const AUDIO_URL = `${CHORAL_API_URL}/audio`;

export async function convert_m4a_to_wav(recording) {
    let ffmpeg = createFFmpeg({
        log: false,
    });

    await ffmpeg.load();

    ffmpeg.FS(
        'writeFile',
        'tmp.m4a',
        await fetchFile(
            URL.createObjectURL(recording)
        )
    );

    await ffmpeg.run('-i', 'tmp.m4a', 'tmp.wav');
    let data = ffmpeg.FS('readFile', 'tmp.wav');

    let conv_recording = new Blob([data.buffer], { type: 'audio/wav' });
    return conv_recording;
}

export async function server_is_reachable() {
    let is_reachable = await fetch(PING_URL)
                            .then((response) => {
                                if (response.ok) {
                                    return response.json();
                                } else {
                                    console.error("Server is down");
                                    return false;
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
    return is_reachable;
}

export async function can_sync_audio(numRecordings) {
    // first check the server is reachable
    let is_reachable = await server_is_reachable();

    // short circuit if server down
    return (is_reachable && numRecordings >= 2);
}

export async function syncRecordings(recordings) {
    // first check the server is reachable
    let is_reachable = await can_sync_audio(Object.keys(recordings).length);

    // short circuit if server down
    if (!is_reachable) {
        console.error("Not synchronizing audio...")
        return recordings;
    }

    var data = new FormData();
    for (let dict of recordings) {
        let converted = dict.name.split(".").pop() == 'm4a' ? await convert_m4a_to_wav(dict.recording) : dict.recording;
        let new_name = dict.name.split(".")[0] + '.wav';
        data.append('file', converted, new_name);
    };

	let options = {
		method: 'POST',
		body: data
    };

    let fetch_json = async (url, args) => {
		let response = await fetch(url, args);
		let result = await response.json();
		return result
    }

    let fetch_blob = async (url, args) => {
		let response = await fetch(url, args);
		let result = await response.blob();
		return result
    }

    let syncd_recordings = await fetch_json(SYNC_URL, options)
        .then(async function(recs) {
            let recordings_out = await Promise.all(recs.map(async function(ftuple) {
                let fname = ftuple[0];
                let originalfname = ftuple[1];
                return {name: fname, recording: await fetch_blob(AUDIO_URL + `/${fname}`, {method: 'GET'}), originalfname: originalfname};
            }));
            return recordings_out;
        })
        .catch(function(error) {
            console.error(error);
        });

    let recording_dict = [];
    for (let dict of recordings) {
        let match = syncd_recordings.filter(d => d.originalfname.startsWith(dict.name.split(".")[0]))[0]
        let item = {...dict, recording: match.recording, name: dict.name.split(".")[0] + ".wav"};
        recording_dict.push(item);
    };

    return recording_dict;

}
