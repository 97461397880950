import { Component } from 'react';

class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false
		};
	}

	handleOnClick() {
		this.setState(prevState => ({
			checked: !prevState.checked
		}));
		//doesn't actually get set until after function is fully executed
		if (this.props.parentCallBack) {
			this.props.parentCallBack(this.props.name, !this.state.checked);
		}
	}

	componentWillReceiveProps(parentState) {
		if (parentState.value != this.state.checked) {
			this.setState({
				checked: parentState.value
			})
		}
	}

	render() {
		return (
			<form>
				<p>
					<label>
						<input
							type="checkbox"
							className="filled-in"
							checked={this.state.checked}
							onChange={()=>this.handleOnClick()}
						/>
						<span>{this.props.label}</span>
					</label>
				</p>
			</form>
		);
	}
}

export default Checkbox;