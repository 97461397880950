import { Component } from 'react';

import FoldersPanel from './FoldersPanel';
import FolderContent from './FolderContent';
import Downloader from './Downloader';

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFolderName: "",
			selectedFolderPath: "",
			selectedFolderNumRecordings: 0,
			syncSwitch: true,
			shouldRefresh: false
		}
		this.selectFolder = this.selectFolder.bind(this);
		this.handleSyncSwitch = this.handleSyncSwitch.bind(this);
		this.updateNumRecordings = this.updateNumRecordings.bind(this);
		this.refresh = this.refresh.bind(this);
		this.doneRefresh = this.doneRefresh.bind(this);
	}

	selectFolder(name, path) {
		this.setState({
			selectedFolderName: name,
			selectedFolderPath: path
		});
	}

	handleSyncSwitch(switchState) {
		this.setState({
			syncSwitch: switchState
		});
	}

	updateNumRecordings(num) {
		this.setState({
			selectedFolderNumRecordings: num
		});
	}

	refresh() {
		this.setState({
			shouldRefresh: true
		});
	}

	doneRefresh() {
		this.setState({
			shouldRefresh: false
		});
	}

	render() {
		const link = "https://www.dropbox.com/home/Apps/chorus-record/" + this.state.selectedFolderName;
		return (
			<div id="authed-section">
				<div className="row">
					<div className="col m12">
						<div className="card">
							<div className="row">
								<FoldersPanel
									getCookie={this.props.getCookie}
									selectFolder={this.selectFolder}
								/>
								{this.state.selectedFolderName == ""
									? <div></div>
									: <FolderContent
										getCookie={this.props.getCookie}
										dbx_expires_timestamp={this.props.dbx_expires_timestamp}
										selectedFolderName={this.state.selectedFolderName}
										selectedFolderPath={this.state.selectedFolderPath}
										handleSyncSwitch={this.handleSyncSwitch}
										selectedFolderNumRecordings={this.state.selectedFolderNumRecordings}
									/>
								}
							</div>
						</div>
						<div className="card">
							<div className="card-content">
								{this.state.selectedFolderName == ""
						    		? <h5 style={{paddingLeft: "10px"}}>
						    			Please select a folder
						    		</h5>
						    		: <div className="row valign-wrapper"
						    				style={{marginBottom: "0"}}>
						    			<h5 className="col s1" style={{margin: "0px 0px 0px 10px"}}>
						    				{this.state.selectedFolderName}
						    			</h5>
						    			<i className="material-icons col s1 grey-text left-align tooltipped"
						    			   style={{cursor: "pointer", margin: "0px", paddingTop: "2px"}}
						    			   data-position="right"
						    			   data-tooltip="Refresh Folder"
						    			   onClick={() => this.refresh()}>
						    				refresh
						    			</i>
						    			<a className="material-icons col s1 offset-s9 grey-text tooltipped"
						    			   style={{cursor: "pointer", paddingTop: "2px"}}
						    			   data-position="left"
						    			   data-tooltip="Open Dropbox"
						    			   href={link}
						    			   target="_blank" rel="noopener noreferrer">
						    				call_made
						    			</a>
							    	</div>
						    	}
							</div>
							{this.state.selectedFolderName == ""
								? <div></div>
								: <Downloader
									syncSwitch={this.state.syncSwitch}
									selectedFolderPath={this.state.selectedFolderPath}
									selectedFolderName={this.state.selectedFolderName}
									getCookie={this.props.getCookie}
									updateNumRecordings={this.updateNumRecordings}
									shouldRefresh={this.state.shouldRefresh}
									doneRefresh={this.doneRefresh}
								/>
							}
						</div>
					</div>
				</div>
			</div>

		);
	}
}

export default Auth;
