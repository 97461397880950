import { Component } from 'react';


class Navbar extends Component {
	render() {
		return (
		<div>
			<div className="navbar-fixed" style={{zIndex: "1001"}}>
				<nav>
					<div className="nav-wrapper teal">
						<a href="#" className="brand-logo">Choral</a>
						<a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
						<ul className="right hide-on-med-and-down">
							<li>
								{
									this.props.loggedIn 
										? <a onClick={this.props.handleLogOut} className="button">
											<span> Log Out </span>
										</a>
										: <a href={this.props.authUrl} className="button">
											<span> Sign In </span>
										</a>
								}
							</li>
							<li>
								<svg className="dropbox-logo" role="img" width="32px" height="32px" viewBox="0 0 32 32">
									<path d="M8 2.4l8 5.1-8 5.1-8-5.1 8-5.1zm16 0l8 5.1-8 5.1-8-5.1 8-5.1zM0 17.7l8-5.1 8 5.1-8 5.1-8-5.1zm24-5.1l8 5.1-8 5.1-8-5.1 8-5.1zM8 24.5l8-5.1 8 5.1-8 5.1-8-5.1z"></path>
								</svg>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<ul className="sidenav" id="mobile-demo">
				<li>
					{
						this.props.loggedIn 
							? <a onClick={this.props.handleLogOut} className="button">
								<span> Log Out </span>
							</a>
							: <a href={this.props.authUrl} className="button">
								<span> Sign In </span>
							</a>
					}
				</li>
				<li>
					<svg className="dropbox-logo" role="img" width="32px" height="32px" viewBox="0 0 32 32">
						<path d="M8 2.4l8 5.1-8 5.1-8-5.1 8-5.1zm16 0l8 5.1-8 5.1-8-5.1 8-5.1zM0 17.7l8-5.1 8 5.1-8 5.1-8-5.1zm24-5.1l8 5.1-8 5.1-8-5.1 8-5.1zM8 24.5l8-5.1 8 5.1-8 5.1-8-5.1z"></path>
					</svg>
				</li>
			</ul>
		</div>
		);
	}
}

export default Navbar;
