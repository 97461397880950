import { Component } from 'react';
import Checkbox from './Checkbox';
import Loader from './Loader';

import M from "materialize-css";

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { downloadFolder } from '../dropboxUtils';
import { syncRecordings } from '../audioProcessing';
import { Howl } from 'howler';
import { format, parseISO} from 'date-fns';

import AudioPlayer from './AudioPlayer';

class Downloader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedPath: "",
			recordings: [],
			syncedRecordings: [],
			selectedRecordings: new Set(),
			playing: false,
			loaded: false,
		};
		this.downloadZip = this.downloadZip.bind(this);
		this.downloadFiles = this.downloadFiles.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	playMusic(indx) {
		var new_recordings = this.props.syncSwitch ? this.state.syncedRecordings : this.state.recordings;
		var url = URL.createObjectURL( new_recordings[indx].recording );
		var sound = new Howl({
		  src: [url],
		  format: [new_recordings[indx].name.split(".").pop()],
		  html5: true
		});
		sound.play();
	}

	playSelected() {
		if (this.state.selectedRecordings.size == 0) {
			alert("Please select at least one recording")
		 	return;
		} else {
			var new_recordings = this.props.syncSwitch ? this.state.syncedRecordings : this.state.recordings;
			if (this.state.playing) {
				window.Howler.stop();
			} else {
				new_recordings.forEach((data) => {
				  if (this.state.selectedRecordings.has(data.name)) {
					var url = URL.createObjectURL( data.recording );
					var sound = new Howl({
					  src: [url],
					  format: [data.name.split(".").pop()],
					  html5: true
					});
					sound.play();
				  }
				});
			}
			this.setState( prevState => ({
				playing: !prevState.playing
			}));
		}
	}

	downloadFiles(path, token) {

		downloadFolder(path, token)
			.then((recordings) => {
				return recordings.filter(dict => dict.name.endsWith(".m4a") || dict.name.endsWith(".wav"));;
			})
			.then((recordings) => {
				this.setState({
					recordings: recordings,
				});
				this.props.updateNumRecordings(recordings.length);
				return syncRecordings(recordings);
			})
			.then((syncRecordings) => {
				this.setState({
					syncedRecordings: syncRecordings,
					loaded: true
				});
			})
			.catch(function(error) {
				console.error(error);
			});
	}

	downloadZip(){
		if (this.state.selectedRecordings.size == 0) {
			alert("Please select at least one recording")
		 	return;
		} else {
			let zip = new JSZip();
			let selectedFolderName = this.props.selectedFolderName; // because anon. fx cannot access this
			var new_recordings = this.props.syncSwitch ? this.state.syncedRecordings : this.state.recordings;
			new_recordings.forEach((data) => {
			  if (this.state.selectedRecordings.has(data.name)) {
				zip.file(data.name, data.recording);
			  }
			});
			zip.generateAsync({type:"blob"})
			.then(function(content) {
				saveAs(content, selectedFolderName + ".zip");
			});
		}

	}

	handleSelect(name, state) {
		var newSelectedRecordings;
		if (name == "selectAll") {
			newSelectedRecordings = new Set();
			if (state) {
				let active_recordings = this.props.syncSwitch ? this.state.syncedRecordings : this.state.recordings;
				active_recordings.forEach(function(dict) {
					newSelectedRecordings.add(dict.name)
				});
			}

		} else if (this.state.selectedRecordings.has(name)) {
			newSelectedRecordings = this.state.selectedRecordings
			newSelectedRecordings.delete(name);

		} else {
			newSelectedRecordings = this.state.selectedRecordings
			newSelectedRecordings.add(name);
		}

		this.setState({
			selectedRecordings: newSelectedRecordings
		});
	}

	componentDidMount() {
		M.AutoInit();
		this.setState({
			selectedPath: this.props.selectedFolderPath,
			loaded: false
		});
		this.downloadFiles(this.props.selectedFolderPath, this.props.getCookie());
	}

	componentWillReceiveProps(parentState) {
		if (parentState.selectedFolderPath != this.state.selectedPath || parentState.shouldRefresh) {
			this.setState({
				selectedPath: parentState.selectedFolderPath,
				loaded: false
			});
			this.downloadFiles(parentState.selectedFolderPath, parentState.getCookie());
			this.props.doneRefresh();
		}
	}

	render() {
		var new_recordings = this.props.syncSwitch ? this.state.syncedRecordings : this.state.recordings;
		return (
			<div className="row">
				<div className="col s12">
					<ul className="tabs z-depth-1 tabs-fixed-width">
						<li className="tab col s4"><a className="active" href="#selection">Recording Selection</a></li>
						<li className="tab col s4"><a href="#playback">Audio Playback</a></li>
						<li className="tab col s4"><a href="#analysis">Analysis</a></li>
					</ul>
				</div>
				<div id="selection" className="col s12">
					<div className="row">
						<div className="col m6">
							<a
								id="download-zip"
								onClick={() => this.downloadZip()}
								className="waves-effect waves-light btn teal lighten-2 centered"
								style={{width: "97.5%", marginLeft: "1.25%", marginRight: "1.25%"}}
							>
								<span>Download Zip</span><i className="material-icons">file_download</i>
							</a>
						</div>
						<div className="col m6">
							<a
								id="download-zip"
								onClick={() => this.playSelected()}
								className="waves-effect waves-light btn teal lighten-2 centered"
								style={{width: "97.5%", marginLeft: "1.25%", marginRight: "1.25%"}}
							>
								<span>{this.state.playing ? "STOP SELECTED" : "PLAY SELECTED"}</span><i className="material-icons">{this.state.playing ? "stop" : "play_arrow"}</i>
							</a>
						</div>
					</div>
					{
						this.state.loaded
						? <table className="responsive-table highlight centered bordered">
				        <thead>
				          <tr>
				              <th>
				              	<Checkbox
				              		name="selectAll"
									parentCallBack={this.handleSelect}
								/>
								</th>
				              <th>Recording Name</th>
				              <th>Download</th>
				          </tr>
				        </thead>

				        <tbody id="style-1">
							{new_recordings.map((item, indx) => {
								var tmp =
									<tr key={item.name}>
										<td>
											<Checkbox
												name={item.name}
												value={this.state.selectedRecordings.has(item.name)}
												parentCallBack={this.handleSelect}
											/>
										</td>
										<td>
											<div>
												<span>{item.name}</span>
												<p style={{fontSize: "small"}} className="grey-text lighten-1">
													{format(parseISO(item.server_modified), 'MMM d, yyyy, pp')}
												</p>
											</div>
										</td>
										<td>
											<a href={URL.createObjectURL(item.recording)} download={item.name}><i className="material-icons" style={{color: "#4db6ac"}}>file_download</i></a>
										</td>
									</tr>
								return tmp;
							})}
				        </tbody>
				    </table>
						: <Loader />
					}
				</div>
				<div id="playback" className="col s12">
					{this.state.loaded
						? <AudioPlayer
							recordings={new_recordings}
						/>
						: <Loader />
					}
				</div>
				<div id="analysis" className="col s12">Coming Soon</div>
			</div>
		);
	}
}

export default Downloader;
