import { Component } from 'react';

class Switch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			switched: true
		};
	}

	handleOnClick() {
		if (this.props.canSyncAudio) {
			this.setState(prevState => ({
				switched: !prevState.switched
			}))
			this.props.parentCallBack(!this.state.switched);
		} else {
			this.setState(prevState => ({
				switched: false
			}))
			this.props.parentCallBack(false);
		}
	}

	render() {
		return (
			<div className="switch">
				<label>
				{this.props.leftLabel}
				{
					this.props.canSyncAudio ?
					<input type="checkbox" checked={this.state.switched} onChange={()=>this.handleOnClick()} />
					:
					<input type="checkbox" checked={false} onChange={()=>this.handleOnClick()} disabled/>

				}
				<span className="lever"></span>
				{/* {this.props.canSyncAudio ? "" : this.props.rightLabel} */}
				</label>
			</div>
		);
	}
}

export default Switch;