export async function downloadFolder(folder_path, access_token) {
	let recordings = listFiles(folder_path, access_token)
						.then(async function(results) {
							let responseArray = await Promise.all(results.map(async function(item) {
								return {...item, recording: await downloadFile(item.path_lower, access_token)};
							}));
							return responseArray;

						}).catch(function(error) {
							console.error(error);
						});
	return recordings
}

export async function downloadFile(path, access_token) {
	let url = 'https://content.dropboxapi.com/2/files/download';
	let options = {
		method: 'POST',
		headers: {
		  "Authorization": `Bearer ${access_token}`,
		  "Dropbox-API-Arg": JSON.stringify({ "path": path })
		}
	};
	let fetchDataFromApi = async (url, args) => {
		let response = await fetch(url, args);
		let result = await response.blob();
		return result
	}

	let recording = fetchDataFromApi(url, options)
		.then((result) => {
			return result
		})
		.catch(function(error) {
			console.error(error);
		});

	return recording
}

export async function listFiles(path, access_token) {
	let url = 'https://api.dropboxapi.com/2/files/list_folder';
	let args = {
		method: 'POST',
		headers: {
		  "Authorization": `Bearer ${access_token}`,
		  "Content-Type": "application/json"
		},
		body: JSON.stringify({
			"path": path
		})
	};
	let response = await fetch(url, args);
	let result = await response.json();
	let cursor = result.cursor;
	let items = result.entries;

	while (result.has_more) {
		let options = {
			method: 'POST',
			headers: {
			  "Authorization": `Bearer ${access_token}`,
			  "Content-Type": "application/json"
			},
			body: JSON.stringify({
				"cursor": cursor
			})
		};
		response = await fetch('https://api.dropboxapi.com/2/files/list_folder/continue', options);
		result = await response.json();
		cursor = result.cursor;
		items = items.concat(result.entries);
	}
	return items;
};

export async function createFolder(path, access_token) {
	let url = 'https://api.dropboxapi.com/2/files/create_folder_v2';
	let args = {
		method: 'POST',
		headers: {
		  "Authorization": `Bearer ${access_token}`,
		  "Content-Type": "application/json"
		},
		body: JSON.stringify({
			"path": safePath(path),
			"autorename": false
		})
	};
	let response = await fetch(url, args);
	let result = await response.json();
	return result;
};


export function safePath(path) {
	// convert slashes to dashes in folder creation input
	let sp = path;
	sp = sp.split('/').join('-');
	sp = sp.split('\\').join('-');
	return sp
};