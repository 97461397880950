import { Component } from 'react';
import './PreAuth.css';
import './timeline.css';

class PreAuth extends Component {
	render() {
		return (
			<div>
				<section>
					<div className="container main">
						<div className="row">
							<div className="col s3 offset-s1">
								<img
									className="responsive-img"
									style={{marginTop: "3vh",
											marginBottom: "1vh"}}
									src="app.png"
								/>
								<div className="row" style={{"marginBottom": "10px"}}>
									<img
										className="col s6 responsive-img"
										src="os-app-black.svg"
									/>
									<img
										className="col s6 responsive-img"
										src="google-play-badge.png"
									/>
								</div>
							</div>
							<div className="col s7 offset-s1">
								<div className="row">
									<h4 className="col s12 left"
										style={{color: "white", fontSize: "44px", marginTop: "135px", marginBottom: "0", padding: "0", "fontWeight": "700", "textShadow": "1px 1px #bbbbbb"}}
									>
										Record in Sync
									</h4>
								</div>
								<div className="row">
									<h4 className="col s12 left"
										style={{color: "white", fontSize: "16px", margin: "0", padding: "0"}}
									>
										Upload, synchronize, and analyze multitrack audio instantly from anywhere.
									</h4>
								</div>
								<div className="row">
									<a
										className="col s12 left waves-effect waves-light btn-large"
										style={{border: "10px"}}
										href={this.props.authUrl}
									>
										Get Started
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="wave wave1"></div>
					<div className="wave wave2"></div>
					<div className="wave wave3"></div>
					<div className="wave wave4"></div>
				</section>
				<div className="content" style={{paddingBottom: "25px", marginTop: "-20px"}}>
					<div className="row" style={{marginLeft: "5vw", marginRight: "5vw"}}>
						<div className="col s12 m4">
							<h2 className="center light-teal-text"><i className="material-icons" style={{fontSize: "60px"}}>clear_all</i></h2>
							<h5 className="center">Record</h5>
							<p className="light center">Multitrack audio recording made simple with Choral</p>
						</div>
						<div className="col s12 m4">
							<h2 className="center light-teal-text"><i className="material-icons" style={{fontSize: "60px"}}>people_outline</i></h2>
							<h5 className="center">Synchronize</h5>
							<p className="light center">Easily synchronize audio recorded at the same time across multiple devices</p>
						</div>
						<div className="col s12 m4">
							<h2 className="center light-teal-text"><i className="material-icons" style={{fontSize: "60px"}}>equalizer</i></h2>
							<h5 className="center">Analyze</h5>
							<p className="light center">Analyze musical aspects of individual audio tracks and export with ease</p>
						</div>
					</div>
				</div>
				<div className="instructions">
					<div
						className="row"
						style={{marginTop: "3vh",  marginLeft: "5vw", marginRight: "5vw"}}
					>
						<h5 className="center"
							style={{marginLeft: "1vw", paddingBottom: "10px"}}
						>
							How it Works
						</h5>
						<div className="col s12">
							<ul className="tabs z-depth-1 tabs-fixed-width">
								<li className="tab col s6"><a className="active" href="#leaders">Group Leaders</a></li>
								<li className="tab col s6"><a href="#members">Group Members</a></li>
							</ul>
						</div>
						<div id="leaders" className="col s12">
							<div class="timeline">
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Connect<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Sign in with Dropbox on the top right corner of the website</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge red white-text"><i class="material-icons">language</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Select<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Create/select a recording folder to collect all the audio files</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge orange white-text"><i class="material-icons">folder_open</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Scan<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Let group members scan the QR code on the website using the Choral app</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge green white-text"><i class="material-icons">person</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Analyze<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Synchronize and playback all uploaded audio files in the browser.</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge blue white-text"><i class="material-icons">graphic_eq</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											{/*activator in span class to activate card reveal*/}
											<span class="card-title grey-text text-darken-4">Download<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Download zipped audios to your local computer as synchronized multi-track audio files</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge purple white-text"><i class="material-icons">file_download</i></div>
								</div>
							</div>
						</div>

						<div id="members" className="col s12">
							<div class="timeline">
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">

											<span class="card-title grey-text text-darken-4">Download<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Download Choral from the <a href="#">Google Play</a> or <a href="#">Apple Stores</a></p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge red white-text"><i class="material-icons">file_download</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Name<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Enter the filename of your recording</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge orange white-text"><i class="material-icons">folder_shared</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Scan<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Scan the QR code displayed on the group leader's website</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge green white-text"><i class="material-icons">photo_camera</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Record<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Start recording before your leader's clapping sounds, and perform your music part</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge blue white-text"><i class="material-icons">mic</i></div>
								</div>
								<div class="timeline-event">
									<div class="card timeline-content">
										<div class="card-content">
											<span class="card-title grey-text text-darken-4">Upload<i class="material-icons right">more_vert</i></span>
											<p style={{color: "grey"}}>Click upload to share your recording with your group leader</p>
										</div>
										<div class="card-reveal">
											<span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
											<p style={{color: "grey"}}>Here is some more information about this product that is only revealed once clicked on.</p>
										</div>
									</div>
									<div class="timeline-badge purple white-text"><i class="material-icons">file_upload</i></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PreAuth;
