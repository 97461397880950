import { Component } from 'react';

class InputRange extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "0"
		}
		this.onChangeListener = this.onChangeListener.bind(this);
	}

	onChangeListener(e) {
		this.setState({
			value: e.target.value
		})
		this.props.parentCallBack(e.target.value);
	}

	componentWillReceiveProps(parentState) {
		if (parentState.progress || parentState.progress === 0) {
			this.setState({
				value: parentState.progress.toString()
			});
		}

	}

	render() {
		return (
			<div className="input-range">
				<input
					type="range"
					onChange={this.onChangeListener}
					className="input-range__slider col s8"
					value={this.state.value}
					min="0" max="100" step=".1" defaultValue="0"
				/>
			</div>
		);
	}
}

export default InputRange;