import { Dropbox } from 'dropbox';
import { Component } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { fromUnixTime, format } from 'date-fns';

import Navbar from './components/Navbar';
import PreAuth from './components/PreAuth';
import Auth from './components/Auth';
import M from "materialize-css";

import { getAccessTokenFromUrl, getTokenExpiresInFromUrl, getDomainFromUrl} from './utils';

const CLIENT_ID = 'm0mmsgi2h74se9k';

class App extends Component {
	constructor(props) {
		super(props);

		this.DEPLOYED_DOMAIN_NAME = getDomainFromUrl();

		this.dbx = new Dropbox({ clientId: CLIENT_ID });
		this.state = {
			loggedIn: false,
			dbx_expires_timestamp: null,
			authUrl: this.dbx.auth.getAuthenticationUrl(this.DEPLOYED_DOMAIN_NAME),
		};

	}

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	getCookie = () => {
		var cookieVal = this.props.cookies.get("dbx_access_token");
		if (cookieVal === undefined) {
			this.setState({
				loggedIn: false,
			});
		} else {
			return cookieVal;
		}
	}

	handleLogOut = (e) => {
		e.preventDefault();
		const { cookies } = this.props;
		cookies.remove("dbx_access_token");
		cookies.remove("dbx_expires_timestamp");
		this.setState({
			loggedIn: false,
		});
	}

	handleCookie = () => {
		const { cookies } = this.props;
		var expirationDate = new Date();
		expirationDate.setSeconds(expirationDate.getSeconds() + parseInt(getTokenExpiresInFromUrl()))
		cookies.set("dbx_access_token",
					getAccessTokenFromUrl(),
					{
						path: "/",
						expires: expirationDate
					});
		cookies.set("dbx_expires_timestamp",
					format(fromUnixTime(Math.round(Date.now()/1000) + parseInt(getTokenExpiresInFromUrl())), 'eee, pp'),
					{
						path: "/",
						expires: expirationDate
					});
	}

	componentDidMount() {
		M.AutoInit();
		if (this.isAuthenticated()) {
			this.setState({
				loggedIn: true,
				dbx_expires_timestamp: this.props.cookies.get("dbx_expires_timestamp")
			});
		} else {
			if (getAccessTokenFromUrl()) {
				this.handleCookie();
				window.location = this.DEPLOYED_DOMAIN_NAME;
			} else {
				this.setState({
					loggedIn: false,
				});
			}
		}
	}

	isAuthenticated() {
		const { cookies } = this.props;
		return cookies.get("dbx_access_token") !== undefined;
	}

	render() {
		return (
			<div className="App">
				<Navbar loggedIn={this.state.loggedIn} authUrl={this.state.authUrl} handleLogOut={this.handleLogOut}/>
				
				{this.state.loggedIn
					? <div className="container main">
						<Auth getCookie={this.getCookie} dbx_expires_timestamp={this.state.dbx_expires_timestamp}/>
					</div>
					: <PreAuth authUrl={this.state.authUrl}/>
				}

			</div>
		);
	}
}

export default withCookies(App);
