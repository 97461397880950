import { Component } from 'react';

import { listFiles } from '../dropboxUtils';
import CreateFolder from './CreateFolder';

import './FoldersPanel.css';

class FoldersPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			folders: [],
			searchInput: ""
		}

		this.handleFolderClick = this.handleFolderClick.bind(this);
		this.addFolder = this.addFolder.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	componentDidMount() {
		listFiles("", this.props.getCookie())
			.then((items) => {
				this.setState({
					folders: items,
					searchInput: ""
				});
			});
	}


	handleFolderClick(event) {
		let attr = event.target.attributes;
		this.props.selectFolder(attr.name.value, attr.path.value);
	}

	addFolder(data) {
		let newFolders = this.state.folders;
		newFolders.push(data);
		this.setState({
			folders: newFolders
		})
	}

	handleSearch(event) {
		var input = event.target.value.toUpperCase();
		var og_folders = this.state.folders;
		og_folders.forEach(data => {
			if (data.name.toUpperCase().indexOf(input) > -1) {
				data['shouldFilter'] = false;
			} else {
				data['shouldFilter'] = true;
			}
		});
		this.setState({
			searchInput: event.target.value,
			folders: og_folders
		});
	}

	render() {
		return (
			<div className="col m4 center-align" style={{paddingBottom: "10px"}}>
				<h5 style={{paddingLeft: "10px"}}> Folders </h5>
				<CreateFolder getCookie={this.props.getCookie} addFolder={this.addFolder}/>
				<div className="input-field" style={{margin: "0px", paddingLeft: "10px"}}>
					<input
						id="search_folder"
						type="text"
						value={this.state.searchInput}
						onChange={this.handleSearch}
					/>
					<label htmlFor="search_folder" style={{margin: "0px", paddingLeft: "10px"}}>Search Folders</label>
				</div>
				<ul className="collection recording-collection" id="style-1">
					{this.state.folders.map((item, index) => {
						if (index == 0) {
							return <li
										key={`folder-${index}`}
										className="collection-item recording-item"
										onClick={this.handleFolderClick}
										style={{borderTop: "1px solid rgb(224, 224, 224)", display: (item.shouldFilter ? "" : "")}}
										name={item.name}
										path={item.path_lower}
									>{item.name}
									</li>;
						} else {
							return <li
										key={`folder-${index}`}
										className="collection-item recording-item"
										onClick={this.handleFolderClick}
										style={{display: (item.shouldFilter ? "none" : "")}}
										name={item.name}
										path={item.path_lower}
									>{item.name}
									</li>;
						}
					})}
				</ul>
			</div>
		);
	}
}

export default FoldersPanel;