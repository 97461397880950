import { Component } from 'react';

import Settings from './Settings';
import QRSection from './QRSection';


class FolderContent extends Component {
	render() {
		return (
			<div className="col m8">
				<div className="row">
					<Settings
						handleSyncSwitch={this.props.handleSyncSwitch}
						selectedFolderNumRecordings={this.props.selectedFolderNumRecordings}
					/>	
					<QRSection
						getCookie={this.props.getCookie}
						selectedFolderPath={this.props.selectedFolderPath}
						dbx_expires_timestamp={this.props.dbx_expires_timestamp}
					/> 
				</div>
			</div>
		);
	}
}

export default FolderContent;