import { Component } from 'react';

import M from "materialize-css";

class Select extends Component {
	constructor(props) {
		super(props);
		this.handleOnClick = this.handleOnClick.bind(this);
	}

	componentDidMount() {
		M.AutoInit();
	}

	handleOnClick(e) {
		e.preventDefault();
		this.props.parentCallBack(e.target.value);
	}

	render() {
		return (
			<div className="input-field col s12">
				<select defaultValue={'clap'} onChange={this.handleOnClick}>
					{this.props.selectData.map((data) => {
						if (data.name == 'clap') {
							return <option key={data.name} value={data.name}>{data.value}</option>;
						}
						else {
							return <option disabled key={data.name} value={data.name}>{data.value}</option>;
						}
					})}
				</select>
				<label>{this.props.label}</label>
			</div>
		);
	}
}

export default Select;