import { Component } from 'react';

import M from "materialize-css";
import QRCode from 'qrcode.react';

class QRSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			folderLink: ""
		}
	}

	setQR() {
		let qr_code_data = {path: this.props.selectedFolderPath, token: this.props.getCookie()};
		this.setState({
			folderLink: JSON.stringify(qr_code_data)
		});
	}

	componentDidMount() {
		M.AutoInit();
		this.setQR();
	}

	componentWillReceiveProps(parentState) {
		this.setQR();
	}

	render() {
		return (
			<div className="col m6 center-align">
				<h5>
	    			Scan to Upload
	    		</h5>
				<div className="row no-margin modal-trigger" href="#modal1" style={{cursor: "pointer"}}>
					<QRCode id="record-qr-code" value={this.state.folderLink} size={200} />
				</div>
				<div className="row no-margin">
					<h6 className="no-margin grey-text lighten-1" style={{fontSize: "small"}}>
						Expires: {this.props.dbx_expires_timestamp}
					</h6>
				</div>
				<div id="modal1" className="modal">
					<div className="modal-footer">
						<a href="#!" className="modal-close waves-effect waves-green btn-flat">x</a>
					</div>
					<div className="modal-content" style={{marginTop: "-60px", padding: "0px"}}>
						<QRCode id="record-qr-code" value={this.state.folderLink} size={500} />
					</div>
				</div>

			</div>

		);
	}
}

export default QRSection;
